import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import Layout from '../components/Layouts/default'
import ImageZoom from 'react-medium-image-zoom'
import { StaticImage } from 'gatsby-plugin-image'

export default function MenuPage() {
  return (
    <Layout>
      <Container style={{ textAlign: 'center', marginTop: 30 }}>
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column textAlign="center">
              <ImageZoom>
                <StaticImage
                  alt="Menu đồ ăn, đồ uống"
                  src="https://s3.kstorage.vn/savor-bread/src/assets/images/menu/menu_20241002.jpg"
                />
              </ImageZoom>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Layout>
  )
}
